export const lmsGraphQlEndpoint = process.env.VUE_APP_GRAPHQL_ENDPOINT;

const EN_ERROR_MESSAGES = {
    // errors thrown by the backend
    afterStudentHasFutureCharge: 'after student has future charge.',
    afterStudentIsNotRegistered: 'after student is not registered.',
    defaultError: 'An error has occured (default)',
    invalidAfterStudent: 'after student invalid.',
    invalidDepartmentId: 'department_id invalid.',
    invalidStudent: 'student invalid.',
    studentHasBooking: 'student has book.',
    studentHasNoCharge: 'student has not charge.',
    studentHasNoStudentProduct: 'student has not student product.',
    unauthenticated: 'Unauthenticated.',
}

const JA_ERROR_MESSAGES = {
    [EN_ERROR_MESSAGES.afterStudentHasFutureCharge]: '追加する受講生が既にチャージを保有しています。',
    [EN_ERROR_MESSAGES.afterStudentIsNotRegistered]: '追加する受講生は登録されていません。',
    [EN_ERROR_MESSAGES.invalidAfterStudent]: '追加する受講生が無効です。',
    [EN_ERROR_MESSAGES.invalidDepartmentId]: 'DIDが無効です。',
    [EN_ERROR_MESSAGES.invalidStudent]: '削除する受講生が無効です。',
    [EN_ERROR_MESSAGES.studentHasBooking]: '削除する受講生が受講予定があります。',
    [EN_ERROR_MESSAGES.studentHasNoCharge]: '削除する受講生がチャージを保有しません。',
    [EN_ERROR_MESSAGES.studentHasNoStudentProduct]: '削除する受講生が契約を保有しません。',
    [EN_ERROR_MESSAGES.unauthenticated]: 'セッションが切れています。再ログインしてください。',
    defaultError: 'エラーが発生しました。'
}

export {
    JA_ERROR_MESSAGES
};
