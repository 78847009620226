import { dateFormat, fullNameEnglish, fullNameKanji } from '@/utils/common';
import { isEmpty } from 'lodash';
import * as formatId from '@/utils/idParser';
import moment from 'moment';

export default {
    namespaced: true,
  
    state: {
        exchangeStudentFormData: {
            companyId: '',
            departmentId: '',
            lastDayCourse: '',
            orderNumber: null,
            secondStudentId: '',
            startDate: '受講最終日の翌日が受講開始日となります（自動割り当て）',
            studentId: '',
        },
        exchangeFormValid: false,
        exchangeWithExistingStudent: false,
        loading: false,
        exchangeStudentsDetails: null
    },
  
    mutations: {
        SET_EXCHANGE_STUDENT_FORM_DATA(state, payload) {
            state.exchangeStudentFormData = payload;
        },

        SET_EXCHANGE_WITH_EXISTING_STUDENT(state, payload) {
            state.exchangeWithExistingStudent = payload;
        },

        SET_EXCHANGE_STUDENTS_DETAILS(state, payload) {
            state.exchangeStudentsDetails = payload;
        },

        SET_LOADING(state, payload) {
            state.loading = payload;
        },

        SET_EXCHANGE_FORM_VALID(state, payload) {
            state.exchangeFormValid = payload;
        },

        RESET_EXCHANGE_STUDENT_DATA(state) {
            state.exchangeStudentsDetails = null;
            state.exchangeStudentFormData = {
                companyId: '',
                departmentId: '',
                lastDayCourse: '',
                orderNumber: null,
                secondStudentId: '',
                startDate: '受講最終日の翌日が受講開始日となります（自動割り当て）',
                studentId: '',
            }
        },
    },
  
    getters: {
        exchangeStudentDetails: (state) => {
            if (!isEmpty(state.exchangeStudentsDetails)) {
                // Old student charge details
                let data  = state.exchangeStudentsDetails;
                let company = data.department.company;
                let department = data.department;
                let student = data.student;

                let beforeStudentChargeDetails = {
                    companyId: formatId.companyDisplayId(company.id),
                    companyName: company.name,
                    departmentId: formatId.departmentDisplayId(department.id),
                    departmentName: department.name,
                    lastDayOfCourse: dateFormat(data.end_date),
                    oldStudentId: formatId.studentDisplayId(student.student_id),
                    purchaseOrderNumber: data.order_no,
                    studentNameEnglish: fullNameEnglish(student),
                    studentNameKanji: fullNameKanji(student),
                }

                // Details and Course start date of the new student
                let courseEndDate = new Date(data.end_date);
                let newCourseStartDate = moment(courseEndDate).add(1, 'd').format("YYYY/MM/DD");

                let afterStudentDetails = data.after_student ? 
                    {
                        afterStudentStartDate: newCourseStartDate,
                        afterStudentId: formatId.studentDisplayId(data.after_student.student_id),
                        afterStudentNameEn: fullNameEnglish(data.after_student),
                        afterStudentNameKanji: fullNameKanji(data.after_student)
                    } :
                    null;

                return Object.assign({}, beforeStudentChargeDetails, afterStudentDetails);
            }

            return [];
        },
    }
}
