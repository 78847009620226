import {
    configure,
    defineRule,
    ErrorMessage,
    Field as VeeField,
    Form as VeeForm,
} from "vee-validate";
  
import { email } from "@vee-validate/rules";
import moment from 'moment';

configure({
    generateMessage: (context) => {
        const messages = {
            email: `無効なメールアドレスです`,
        };
        
        const message = messages[context.rule.name]
        ? messages[context.rule.name]
        : `The field ${context.field} is invalid`;
        return message;
    },
});

export default {
    install(app) {
        app.component("ErrorMessage", ErrorMessage);
        app.component("VeeField", VeeField);
        app.component("VeeForm", VeeForm);

        defineRule("email", email);
       
        defineRule('email_required', value => {
            if (!value || !value.length) {
              return 'メールアドレスが不正です';
            }
            return true;
        });

        defineRule('required', value => {
            if(!value || !value.length) {
                return '必須項目です'
            }

            return true;
        });
        
        defineRule('password_required', value => {
            if (!value || !value.length) {
              return 'パスワードが不正です';
            }

            return true;
        });

        defineRule('companyId', value => {
            if(!/\b[C]{1}\d{11}\b/g.test(value)) {
                return  `企業IDには、先頭の'C'と11桁の数字を記入ください`
            }

            return true;
        });

        defineRule('departmentId', value => {
            if(!/\b[D]{1}\d{11}\b/g.test(value)) {
                return `部署IDには、先頭の'D'と11桁の数字を記入ください`
            }

            return true;
        });

        defineRule('customerId', value => {
            if(!/\b[S]{1}\d{11}\b/g.test(value)) {
                return `お客様IDには、先頭の'S'と11桁の数字を記入ください`
            }

            return true;
        });

        defineRule('secondCustomerId', value => {
            if (!value || !value.length) {
                return true;
            }

            if(!/\b[S]{1}\d{11}\b/g.test(value)) {
                return `お客様IDには、先頭の'S'と11桁の数字を記入ください`
            }

            return true;
        });

        defineRule('dateFormat', value => {
            if (!moment(value,'YYYY/MM/DD', true).isValid()) {
              return '日付は YYYY/MM/DD 形式で記入ください';
            }

            return true;
        });

        defineRule('orderNumber', value => {
            if(!/^[0-9]*$/.test(value)) {
                return '発注書IDには、数字のみを記入ください'
            }

            return true;
        });
    },
};
